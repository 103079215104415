.navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  padding: 2px;
  box-sizing: border-box;
  justify-content: space-between;
  padding-bottom: 70px;




  .navbar-menu {
    ul {
      list-style: none;
      padding: 0 5px;
      margin-top: 96px;

      li {
        padding: 4px 2px 5px 2px;
        font-size: 10px;
        color: #333;
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;
        text-align: center;

        .nav-icons {
          color: #CCCCCC;
          margin: 5px 20px;

          // &:hover {
          //   color: #74003B;
          // }
        }

        &:hover {
          background-color: #f4f4f4;


        }

        &.active {
          border-radius: 4px;
          background: linear-gradient(0deg, rgba(116, 0, 59, 0.10) 0%, rgba(116, 0, 59, 0.10) 100%), #FFF;
          color: #74003B;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        a {
          text-decoration: none;
          color: #CCCCCC;



          // &:hover {
          //   color: #74003B // Hover color
          // }
        }


        &.active a,
        a.active-link,
        &.active .nav-icons {
          color: #74003B;

        }
      }

      li img {
        width: 28px;
      }
    }
  }

  .profile-btn-container {

    .avatar-btn {
      position: relative;
      border: none;
      background: none;
      cursor: pointer;
      padding: 5px 21px;

      &::after {
        content: "Profile";
        display: none;
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        white-space: nowrap;
        font-size: 12px;
        z-index: 10;
      }

      &:hover::after {
        display: block;
      }
    }

    .avatar-btn img {
      width: 34px;
    }
  }
}

:where(.css-dev-only-do-not-override-6s1het).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-6s1het).ant-dropdown-menu-submenu .ant-dropdown-menu {
  height: 90px !important;

}
.dashboard-container {
  padding: 20px;
  margin-top: -8px;

  .info-cards {
    display: flex;
    gap: 35px;
    margin-bottom: -22px;
    width: 100%;
    box-sizing: border-box;
    padding: 25px 82px 54px 82px;
    justify-content: space-between;

    .company-card,
    .personal-card {
      // flex: 1;
      width: 42%;
      display: flex;
      padding: 20px 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid #EDEFF5;
      background: #FFF;
      transition: transform 0.3s ease, box-shadow 0.3s ease;


      // &:hover {
      //   transform: scale(1.05);
      //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

      // }

      .card-header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 16px;
        justify-content: space-between;
        width: 100%;

        .card-header-1 {
          display: flex;
          flex-direction: column;
        }

        .card-header-2 {
          &:hover {
            color: #f1f1f1;
            background: #74003b;
          }
        }

        .custom-ok-button {
          color: #74003b;
          background: #f1f1f1;

          &:hover {
            color: #f1f1f1;
            background: #74003b;
            border-color: #74003b;
          }

          &:focus {
            background-color: #3e8e41;
            border-color: #3e8e41;
          }
        }



        .card-icon {}

        .card-icon img {
          width: 25px;
          margin-left: 4px;
          margin-top: 4px;

        }

        h3 {
          color: #333;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.96px;
          margin-top: 12px;

        }
      }

      .card-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .card-row {
          display: flex;
          gap: 0px;
          margin-bottom: 16px;

          .card-content-label {
            color: #444;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 50%;
          }

          .card-content-value {
            color: #272927;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 50%;
            text-align: right;
            padding-right: 11px;
          }
        }


      }
    }
  }

  .pan-users-section {
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 16px;
    scroll-behavior: auto;
    width: 75%;

    .add-pan-btn {
      border-radius: 8px;
      border: 1px solid var(--primary-700, #901F59);
      background: #F2F3FA;
      box-shadow: 8px 0px 20px 0px rgba(0, 0, 0, 0.15);
      display: flex;
      padding: 8px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #901F59;
      height: 40px;
      margin-top: 8px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        background: #74003b;
        color: white;
        transform: scale(1.05);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

      }
    }

    .pan-users-scroll {
      margin-top: 16px;
      display: flex;
      gap: 16px;
      overflow-x: auto;
      padding: 8px 0;

      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
      }

      .pan-user-card {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border: 1px solid #e8e8e8;
        min-width: max-content;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
        height: 40px;
        justify-content: center;
        gap: 10px;

        span {
          font-size: 14px;

          &:last-of-type {
            color: #222;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }

  // .ag-theme-alpine {
  //   border-radius: 8px;
  //   overflow: hidden;
  //   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  //   .ag-header {
  //     background-color: #901F59;
  //     color: #fff;
  //     font-weight: bold;
  //     padding: 12px;
  //     height: 51px !important;
  //   }

  //   .ag-header-cell {
  //     color: #fff;
  //     top: -12px !important;
  //     width: 200px !important;
  //     margin-left: 16px !important;
  //   }

  //   .ag-header-cell-label {
  //     justify-content: center;
  //   }

  //   .ag-center-cols-container {
  //     width: 1300px !important;
  //   }

  //   .ag-cell {
  //     padding-left: 5px;
  //     color: #222;
  //     justify-content: center;
  //     display: flex;
  //   }

  //   .ag-row {
  //     height: 60px !important;

  //     &:nth-child(even) {
  //       background: linear-gradient(0deg, rgba(116, 0, 59, 0.05) 0%, rgba(116, 0, 59, 0.05) 100%), #FFF;
  //     }

  //     &:hover {
  //       background-color: #e8e8e8;
  //     }
  //   }

  //   .ag-header-icon .ag-header-cell-filter-button {
  //     .ag-icon .ag-icon-filter {
  //       color: white !important;
  //     }
  //   }

  //   .ag-icon-filter {
  //     color: white;
  //   }

  //   .ag-icon-menu-alt {
  //     color: white;
  //   }

  //   .ag-header-cell-resize {
  //     display: none;
  //   }


  //   .action-buttons {
  //     display: flex;
  //     justify-content: center;
  //     margin-left: 38px;


  //     .ant-btn {
  //       border-color: #901F59;
  //       color: #901F59;
  //       background-color: transparent;

  //       &:hover,
  //       &:focus {
  //         border-color: #7a1950;
  //         color: #7a1950;
  //       }

  //       &.ant-btn-dangerous {
  //         border-color: #cf1322;
  //         color: #cf1322;

  //         &:hover,
  //         &:focus {
  //           border-color: #a8071a;
  //           color: #a8071a;
  //         }
  //       }
  //     }
  //   }

  //   .action-buttons img {
  //     width: 19px;
  //     cursor: pointer;
  //     height: 20px;
  //     margin-top: 8px;
  //     margin-left: 16px;
  //   }
  // }
  .section-container {

    padding: 0px 80px;

    .add-corporates-section {
      height: 60px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 10px auto;
      padding: 6px 45px;
      box-sizing: border-box;
      align-items: center;
      border-radius: 12px;
      // background: var(--text, #FFF);
      // box-shadow: 0px 0px 15px 0px rgba(116, 0, 59, 0.08);
      border-radius: 8px;
      border: 1px solid #EDEFF5;
      background: #FFF;

      .add-corporate-btn {
        border-radius: 8px;
        border: 1px solid var(--primary-700, #901F59);
        background: #FCFCFC;
        box-shadow: 8px 0px 20px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        padding: 8px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #901F59;
        margin: 10px 0px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          background: #74003b;
          color: white;
          transform: scale(1.05);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

        }
      }
    }
  }

}
.business-table {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  .invoice-request-header {
    display: flex;
    justify-content: flex-start;
    width: 95%;
    padding: 25px 30px;
    gap: 24px;

    .stats-section {
      display: flex;
      gap: 24px;
      padding: 20px;
      flex-wrap: wrap;
      width: 100%;
      box-sizing: border-box;
      justify-content: space-evenly;

      // @media (max-width: 768px) {
      //   gap: 16px;
      // }
    }
  }

  .stat-card {
    border-radius: 20px;
    border: 1px solid #EEE;
    opacity: 0.8;
    background: #FFF;
    width: 23%;
    height: 140px;

    .ant-card-body {
      padding: 20px;
    }

    .stat-inner {
      display: flex;
      align-items: center;
      gap: 16px;

      .stat-content {

        .stat-label {
          color: #AAA;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin: 14px 0px
        }

        .stat-value {
          color: #74003B;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          /* 133.333% */
          letter-spacing: -0.48px;
          margin: 2px;
        }
      }

    }

    .stat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      font-size: 24px;
      border-radius: 28px;
      background: #F6F6F6;

      &.purple {
        background: rgba(128, 0, 128, 0.1);
        color: purple;
      }

      &.red {
        background: rgba(255, 0, 0, 0.1);
        color: #ff4d4f;
      }

      &.blue {
        background: rgba(24, 144, 255, 0.1);
        color: #1890ff;
      }

      &.violet {
        background: rgba(138, 43, 226, 0.1);
        color: blueviolet;
      }
    }


    .dropdown-container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 5px;

      .dropdown-text {
        margin-left: 9px;
        color: var(--Content-Primary, #17142A);
        /* font-family: "Noto Sans"; */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

      }

      .dropdown-box {
        padding: 7px 69px;
        border-radius: 8px;
        border: 1px solid var(--Border-Moderate, #D2D1D6);
        background: var(--Surface-Primary-Normal, #FFF);
        font-size: 14px;
        color: #d9d9d9;
      }

      .ant-dropdown-trigger {
        border: 1px solid #C3C4CD;
        padding: 6px 11px;
        font-size: 12px;
        border-radius: 5px;
        color: #C3C4CD;
      }

      .ant-dropdown-trigger .ant-space-gap-col-small {
        column-gap: 310px;
      }
    }
  }

  .invoice-request-table {
    display: flex;
    height: 100%;

    .ag-theme-alpine {
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      flex: 1;

      .ag-header {
        background-color: #901F59;
        color: #fff;
        font-weight: bold;
        padding: 12px;
        height: 51px !important;
      }

      .ag-header-container {
        width: 100% !important;
      }

      .ag-header-row {
        top: -3px !important;
        width: 100% !important;
      }

      .ag-header-cell {
        color: #fff;
        top: -12px !important;
        width: 123px !important;
      }

      .ag-header-cell-label {
        justify-content: center;
      }

      .ag-center-cols-container {
        width: 100% !important;
      }

      .ag-cell {
        padding-left: 5px;
        color: #222;
        justify-content: center;
        display: flex;
        width: 160px !important;
      }

      .ag-row {
        height: 45px !important;

        &:nth-child(even) {
          background: linear-gradient(0deg, rgba(116, 0, 59, 0.05) 0%, rgba(116, 0, 59, 0.05) 100%), #FFF;
        }

        &:hover {
          background-color: #e8e8e8;
        }
      }

      .ag-header-icon .ag-header-cell-filter-button {
        .ag-icon .ag-icon-filter {
          color: white !important;
        }
      }

      .ag-icon-filter {
        color: white;
      }

      .ag-icon-menu-alt {
        color: white;
      }

      .ag-header-cell-resize {
        display: none;
      }


      .action-buttons {
        display: flex;
        justify-content: center;
        margin-left: 38px;


        .ant-btn {
          border-color: #901F59;
          color: #901F59;
          background-color: transparent;

          &:hover,
          &:focus {
            border-color: #7a1950;
            color: #7a1950;
          }

          &.ant-btn-dangerous {
            border-color: #cf1322;
            color: #cf1322;

            &:hover,
            &:focus {
              border-color: #a8071a;
              color: #a8071a;
            }
          }
        }
      }

      .action-buttons img {
        width: 19px;
        cursor: pointer;
        height: 20px;
        margin-top: 8px;
        margin-left: 16px;
      }
    }
  }
}
// Onboarding.scss

.onboarding {
  // background-color: #F9F9F9;
  padding: 20px;


  .ant-steps {
    width: 70%;
    margin: 24px auto;
    padding-bottom: 40px;

    .ant-steps-item {
      &-title {
        font-size: 14px;
        color: #555;
      }

      &-icon {
        border: 2px solid #ABB7C2;
        background: #F9F9F9;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        // background-color: #1890ff;
        color: #74003B;
        border-radius: 50%;
        width: 23px;
        height: 23px;
        margin-left: 24px;

        .ant-steps-icon {
          color: #ABB7C2;
          font-weight: 600;
        }

        &-process {
          border: 2px solid #74003B;
          background: #f9f9f9;
        }

        &-finish {
          border: 2px solid #74003B;
          background: #74003B;
        }

        &-error {
          background-color: #ff4d4f;
        }
      }

      &-process .ant-steps-item-icon {
        border: 2px solid #74003B;
        color: #74003B;

        .ant-steps-icon {
          color: #74003B;
          font-weight: 600;
        }
      }

      // &-process .ant-steps-item-icon {
      //   border: 2px solid #74003B;
      //   color: #74003B;
      // }

      &-finish .ant-steps-item-icon {
        border-color: #74003B;
        background-color: #74003B;

        .ant-steps-icon {
          color: #f9f9f9;
        }
      }

      &-error .ant-steps-item-icon {
        border-color: #ff4d4f;
      }

      // .ant-steps-icon {
      //   color: #74003B;

      //   &-process {
      //     color: #74003B;
      //   }
      // }
    }

    .ant-steps-item-content {
      margin-left: 0px;

      .ant-steps-item-title {
        color: #0D0B26;


      }

      .ant-steps-item-title::after {
        top: -14px;
        left: 75px;
        height: 2px;
        background-color: #ABB7C2 !important;

        &-finish {
          background-color: #74003B !important;
        }
      }


    }

    .ant-steps-item-description {
      font-size: 0.875rem;
      color: #777;
    }
  }

  .ant-steps-horizontal {
    .ant-steps-item {
      &::before {
        background-color: #ccc;
      }

      &-process::before {
        background-color: #40a9ff;
      }

      &-finish::before {
        background-color: #52c41a;
      }

      &-error::before {
        background-color: #ff4d4f;
      }
    }
  }

  // Step Content Area
  .step-content {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  // Custom styling for each step content if needed
  .step-one-content {
    // Add specific styles for Step 1 content here
  }

  .step-two-content {
    // Add specific styles for Step 2 content here
  }

  .step-three-content {
    // Add specific styles for Step 3 content here
  }

  .step-four-content {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: #1890ff;
  }
}
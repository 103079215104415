.registration-container {
  padding: 24px;
  width: 96.5%;
  margin: 0 auto;
  height: 100vh;
  background: #FAFAFA;

  .action-buttons {
    display: flex;
    gap: 16px;
    margin-bottom: 40px;
    justify-content: flex-end;

    .action-btn {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      border-radius: 4px;
      color: #666;
      cursor: pointer;
      font-size: 14px;
      transition: all 0.2s ease;

      &:hover {
        color: var(--primary-700, #74003B);
      }

      &.primary {
        border: 1px solid #901F59;
        color: var(--primary-700, #901F59);

        &:hover {
          background: #901F59;
          color: #ffffff;
        }
      }
    }
  }

  .dashboard-container {
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
    width: 80%;
    height: 438px;
    margin: 0 auto;

    .invoice-request-header {
      display: flex;
      justify-content: flex-start;
      width: 95%;
      padding: 25px 30px;
      gap: 24px;

      .stats-section {
        display: flex;
        gap: 24px;
        padding: 20px;
        flex-wrap: wrap;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-evenly;

        // @media (max-width: 768px) {
        //   gap: 16px;
        // }
      }
    }

    .stat-card {
      border-radius: 20px;
      border: 1px solid #EEE;
      opacity: 0.8;
      background: #FFF;
      width: 23%;


      .ant-card-body {
        padding: 20px;
        border-radius: 16px;
        background: linear-gradient(0deg, rgba(116, 0, 59, 0.10) 0%, rgba(116, 0, 59, 0.10) 100%), #FFF;
      }

      .stat-inner {
        display: flex;
        flex-direction: column;

        .stat-content {

          .stat-label {
            color: #425166;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
            margin: 6px 0px
          }

          .stat-value {
            line-height: 32px;
            /* 133.333% */
            letter-spacing: -0.48px;
            margin: 2px;
            color: #151D48;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            /* 133.333% */
          }

          .stat-description {
            margin: 6px 0px;
            color: #999;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            white-space: pre-line;
            /* 133.333% */
          }
        }

      }

      .stat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 24px;
        border-radius: 28px;
        background: #F6F6F6;

        &.purple {
          background: rgba(128, 0, 128, 0.1);
          color: purple;
        }

        &.red {
          background: rgba(255, 0, 0, 0.1);
          color: #ff4d4f;
        }

        &.blue {
          background: rgba(24, 144, 255, 0.1);
          color: #1890ff;
        }

        &.violet {
          background: rgba(138, 43, 226, 0.1);
          color: blueviolet;
        }
      }


      .dropdown-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 5px;

        .dropdown-text {
          margin-left: 9px;
          color: var(--Content-Primary, #17142A);
          /* font-family: "Noto Sans"; */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

        }

        .dropdown-box {
          padding: 7px 69px;
          border-radius: 8px;
          border: 1px solid var(--Border-Moderate, #D2D1D6);
          background: var(--Surface-Primary-Normal, #FFF);
          font-size: 14px;
          color: #d9d9d9;
        }

        .ant-dropdown-trigger {
          border: 1px solid #C3C4CD;
          padding: 6px 11px;
          font-size: 12px;
          border-radius: 5px;
          color: #C3C4CD;
        }

        .ant-dropdown-trigger .ant-space-gap-col-small {
          column-gap: 310px;
        }
      }
    }

    .instructions {
      color: #222;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      box-sizing: border-box;
      padding: 22px 55px 0px;
    }

    .status-cards {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;
      justify-content: center;
      width: 100%;
      padding: 5px 190px;
      box-sizing: border-box;

      .status-card {
        border-radius: 16px;
        background: linear-gradient(0deg, rgba(116, 0, 59, 0.10) 0%, rgba(116, 0, 59, 0.10) 100%), #FFF;

        padding: 30px 40px;

        background: #FFF;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        gap: 32px;
        flex-direction: column;
        align-items: flex-start;
        width: 25%;
        transition: transform 0.3s ease, box-shadow 0.3s ease;


        &:hover {
          transform: scale(1.05);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

        }

        .card-icon {
          width: 48px;
          height: 48px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;

          &.company {
            border: 1px solid #8b1538;
            border-radius: 33px;
          }

          &.tax {
            border: 1px solid #8b1538;
            border-radius: 33px;
          }

          &.travel {
            border: 1px solid #8b1538;
            border-radius: 33px;
          }

          .icon img {
            width: 30px;
          }
        }

        .card-content {
          display: flex;
          flex-direction: column;
          width: 100%;

          h3 {
            margin: 0 0 15px 0;
            color: #1D1D1D;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .status-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .status {
            font-size: 12px;
            padding: 4px 8px;
            border-radius: 4px;

            &.active {
              background: #58A658;
              color: #e8f5e9;
            }

            &.pending {
              background: #E3BF00;
              color: #fff3e0;
            }
          }

          .edit-btn,
          .complete-btn {
            font-size: 12px;
            padding: 4px 12px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            color: #8b1538;
            background: none;
            text-decoration: underline;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .notification {
      background: #F2F3FA;
      padding: 16px;
      border-radius: 8px;
      margin-bottom: 80px;
      text-align: center;

      p {
        margin: 0;
        font-size: 14px;
        color: #666;

        a {
          color: #2196f3;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .view-invoices-btn {
      width: 91%;
      padding: 12px;
      border: none;
      font-size: 16px;
      cursor: pointer;
      transition: background 0.2s ease;
      border-radius: 12px;
      background: #74003B;
      color: #ffffff;
      margin: 0 52px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;


      &:hover {
        transform: scale(1.01);
        background: #74003B;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

      }
    }
  }

}
@media only screen and (max-width : 480px) {
  .about-us-container {
    flex-direction: column !important;
    padding: 0px !important;
    width: 100% !important;

    .about-us-content {
      width: 100% !important;

      h1 {
        text-align: center !important;
      }

      h2 {
        text-align: center !important;
        font-size: 44px !important;
      }

      p {
        text-align: center !important;
      }

      .know-more-btn {
        margin: 20px 114px !important;
        width: 40% !important;
      }
    }

    .about-us-image {
      justify-content: center !important;
      margin-top: 40px !important;
      width: 100% !important;
    }

    .about-us-image img {
      width: 100% !important;
      padding-right: 10px !important;
    }

  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {
  .about-us-container {
    padding: 0px 30px !important;

    .about-us-content {
      width: 50% !important;

      h1 {
        font-size: 35px !important;
      }

      h2 {
        font-size: 27px !important;
      }

      p {
        font-size: 18px !important;
      }


    }

    .about-us-image {
      width: 50% !important;
    }

    .about-us-image img {
      width: 100% !important;
      padding: 0px !important;
    }
  }
}

@media only screen and (min-width : 821px) and (max-width: 921px) {
  .about-us-container {
    padding: 0px 30px !important;

    .about-us-content {
      width: 50% !important;

      h1 {
        font-size: 35px !important;
      }

      h2 {
        font-size: 27px !important;
      }

      p {
        font-size: 18px !important;
      }


    }

    .about-us-image {
      width: 50% !important;
    }

    .about-us-image img {
      width: 100% !important;
      padding: 0px !important;
    }
  }
}

@media only screen and (min-width : 921px) and (max-width: 1024px) {
  .about-us-container {
    padding: 0px 30px !important;

    .about-us-content {
      width: 50% !important;

      h1 {
        font-size: 35px !important;
      }

      h2 {
        font-size: 27px !important;
      }

      p {
        font-size: 18px !important;
      }


    }

    .about-us-image {
      width: 50% !important;
    }

    .about-us-image img {
      width: 100% !important;
      padding: 0px !important;
    }
  }
}

@media only screen and (min-width : 1024px) and (max-width: 1200px) {}






.about-us-container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 63px 20px 20px 119px;
  background-color: #ffffff;
  position: relative;
  justify-content: space-between;
  box-sizing: border-box;

  .about-us-content {
    // flex: 1;
    // padding-right: 40px;
    width: 100%;

    h1 {
      color: var(--primary-heading-color);
      font-size: 40px;
      margin-bottom: 10px;
      font-weight: 600;
    }

    h2 {
      color: var(--primary-heading-color);
      font-size: 32px;
      margin-bottom: 20px;
      font-weight: 600;
    }

    p {
      color: #343434;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 10px;
      font-weight: 400;
    }

    .know-more-btn {
      background-color: transparent;
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
      padding: 10px 20px;
      font-size: 1em;
      cursor: pointer;
      transition: all 0.3s ease;
      margin-top: 20px;
    }

    // .know-more-btn:hover {
    //   background-color: #8a1538;
    //   color: white;
    // }
  }

  .about-us-image {
    // flex: 1;
    // width: 62%;
    display: flex;
    justify-content: right;
    // align-items: center;


  }

  .about-us-image img {
    width: 50%;
    padding-right: 60px;
    height: auto;
    border-radius: 10px;
  }
}











// @media (max-width: 768px) {
//   .about-us-container {
//     flex-direction: column;
//   }

//   .about-us-content {
//     padding-right: 0;
//     margin-bottom: 30px;
//   }

//   .chat-icon {
//     right: 20px;
//   }
// }
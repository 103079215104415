.MainLayout {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: white;

  .NavigationContainer {
    width: 88px;
    height: 100%;
  }

  .AppContentContainer {
    display: flex;
    flex: 1;
    flex-direction: column;

    .HeaderContainer {
      height: 66px;
    }

    .PageContainer {
      overflow: auto;
      flex: 1;
      background: #fafafa;
    }
  }
}
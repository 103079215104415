@media only screen and (max-width : 480px) {
  .help-container {

    .content-wrapper {
      flex-direction: column !important;
      gap: 27px !important;

      .contact-info {
        margin-right: 1px !important;
        width: 94% !important;
        padding: 5px 11px 5px 60px !important;
      }

      .customer-support-container {
        flex-direction: column !important;
        width: 94% !important;

        .image-container {
          width: 100% !important;
        }

        .image-container img {
          border-radius: 24px !important;
        }

        .support-options {
          width: 100% !important;
          padding: 0px 0px 15px 0px !important;
          text-align: center !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {
  .help-container {
    .content-wrapper {
      width: 100% !important;
      // padding: 19px !important;
      margin-right: 11px !important;

      .contact-info {
        width: 37% !important;
        padding: 19px !important;
        margin-right: 11px !important;
      }

      .customer-support-container {
        width: 58% !important;

        .image-container {
          width: 40% !important;
        }

        .support-options {
          padding: 10px !important;
          width: 61% !important;
        }
      }
    }
  }
}

@media only screen and (min-width : 821px) and (max-width: 921px) {
  .help-container {
    .content-wrapper {
      width: 100% !important;
      // padding: 19px !important;
      margin-right: 11px !important;

      .contact-info {
        width: 37% !important;
        padding: 19px !important;
        margin-right: 11px !important;
      }

      .customer-support-container {
        width: 58% !important;

        .image-container {
          width: 40% !important;
        }

        .support-options {
          padding: 10px !important;
          width: 61% !important;
        }
      }
    }
  }
}

@media only screen and (min-width : 921px) and (max-width: 1024px) {}

@media only screen and (min-width : 1024px) and (max-width: 1200px) {
  .help-container {
    .content-wrapper {
      width: 100% !important;
      // padding: 19px !important;
      margin-right: 11px !important;

      .contact-info {
        width: 37% !important;
        padding: 19px !important;
        margin-right: 11px !important;
      }

      .customer-support-container {
        width: 58% !important;

        .image-container {
          width: 40% !important;
        }

        .support-options {
          padding: 10px !important;
          width: 61% !important;
        }
      }
    }
  }
}

.help-container {
  font-family: Arial, sans-serif;
  width: 89%;
  margin: 0 auto;
  padding: 20px 0px 20px 0px;
  background-color: var(--primary-bg-color);


  h2 {
    color: #333;
    margin-bottom: 10px;
    font-size: 32px;
  }

  p {
    font-size: 20px;
  }

  .content-wrapper {
    display: flex;
    background-color: var(--primary-bg-color);
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    padding: 25px;

    .contact-info {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-right: 70px;
      padding: 40px 53px;
      border-radius: 24px;
      background: var(--text, #FFF);
      box-shadow: 0px 0px 15px 0px rgba(116, 0, 59, 0.08);
      box-sizing: border-box;

      justify-content: center;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      /* Smooth transition for transform and shadow */

      &:hover {
        transform: scale(1.05);
        /* Enlarge by 5% on hover */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        /* Slightly stronger shadow on hover */
      }


      .info-item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }

      .info-item img {
        width: 10%;
        margin-right: 20px;
      }

      .icon {
        margin-right: 10px;
      }
    }

    .customer-support-container {
      display: flex;
      width: 100%;
      border-radius: 24px;
      background: var(--text, #FFF);
      box-shadow: 0px 0px 15px 0px rgba(116, 0, 59, 0.08);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      /* Smooth transition for transform and shadow */

      &:hover {
        transform: scale(1.05);
        /* Enlarge by 5% on hover */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        /* Slightly stronger shadow on hover */
      }

      .image-container {
        width: 40%;
      }

      .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px 0px 0px 24px;
      }

      .support-options {
        padding: 20px 66px 20px 91px;
        width: 40%;

        h3 {
          font-size: 16px;
          font-weight: 400;
          margin-top: 45px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 15px;
        }

        .connect-button {
          background-color: white;
          color: var(--primary-heading-color);
          padding: 11px 20px;
          border-radius: 32px;
          cursor: pointer;
          margin-top: 10px;
          width: 55%;
        }

        .connect-button:hover {
          background-color: var(--primary-color);
          color: white;
        }
      }
    }



  }
}
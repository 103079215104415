@media only screen and (max-width : 480px) {

  .faq-title {
    margin: 20px 78px !important;
  }

  .faq-container {
    padding: 18px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {}

@media only screen and (min-width : 821px) and (max-width: 921px) {}

@media only screen and (min-width : 921px) and (max-width: 1024px) {}

.faq-title {
  color: var(--priamary-800, #74003B);
  text-align: left;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 20px 90px;
}

.faq-container {
  width: 89%;
  // max-width: 86%;
  margin: 0 auto;
  padding: 36px 75px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  .faq-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;

    .faq-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 15px;
      background: none;
      border: none;
      text-align: left;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      transition: all 0.3s ease;


      &:hover {
        transform: scale(1.02);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        border-color: #d0d0d0;

      }

      &:active {
        transform: scale(0.98);
      }
    }

    .faq-icon {
      font-size: 20px;
      transition: transform 0.3s ease;
    }

    .faq-icon.open {
      transform: rotate(180deg);
    }

    .faq-answer {
      padding: 15px;
      background-color: var(--primary-bg-color);
      border-radius: 0 0 8px 8px;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .faq-item:last-child {
    border-bottom: none;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@media only screen and (max-width : 480px) {

  .register-page {
    .register-hero {
      height: 115vh !important;

      .register-hero-bg {
        height: 33% !important;
      }

      .login-container {
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        margin-top: 64px !important;


        .login-left {

          width: 94% !important;
          padding: 12px !important;

          .login-left-content {
            h2 {
              font-size: 21px !important;
            }

            h1 {
              font-size: 29px !important;
            }

            p {
              font-size: 15px !important;
            }
          }
        }

        .login-right {
          width: 78% !important;
          margin-top: 10px !important;
          margin-left: 42px !important;

          .login-form-container {

            .verify-container {
              flex-direction: column !important;
              align-items: center !important;

              .verify-buttons {
                gap: 7px !important;

              }
            }



            .form-group {
              .select-wrapper {
                .ant-dropdown-trigger {
                  padding: 6px !important;
                  width: 162px !important;

                  .ant-space-gap-col-small {
                    column-gap: 112px !important;

                  }
                }
              }

              .input-field {
                &:hover {
                  border: 1px solid #74003B;

                }
              }
            }
          }
        }

      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {

  .register-page {

    .register-hero {
      height: 74vh !important;

      .login-container {
        width: 90% !important;

        .login-left {
          padding: 30px !important;
          width: 40% !important;
          justify-content: flex-start !important;
          margin-top: 36px !important;
        }

        .login-right {
          width: 63% !important;

          .login-form-container {

            h2 {
              margin-bottom: 50px !important;
            }

            .verify-container {
              flex-direction: column !important;
              align-items: center !important;

              .verify-buttons {
                gap: 7px !important;

              }
            }

            .form-group {

              .select-wrapper {
                .ant-dropdown-trigger {
                  width: 112px !important;

                  .ant-space-gap-col-small {
                    column-gap: 181px !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .register-hero-bg {
      height: 61% !important;
    }
  }
}

@media only screen and (min-width : 821px) and (max-width: 921px) {
  .register-page {

    .register-hero {
      height: 67vh !important;

      .login-container {
        width: 90% !important;

        .login-left {
          padding: 30px !important;
          width: 40% !important;
          justify-content: flex-start !important;
          margin-top: 36px !important;
        }

        .login-right {
          width: 63% !important;

          .login-form-container {

            h2 {
              margin-bottom: 50px !important;
            }

            .verify-container {
              flex-direction: column !important;
              align-items: center !important;

              .verify-buttons {
                gap: 7px !important;

              }
            }
          }
        }
      }
    }

    .register-hero-bg {
      height: 68% !important;
    }
  }
}

@media only screen and (min-width : 921px) and (max-width: 1024px) {
  .register-page {

    .register-hero {
      height: 60vh !important;

      .login-container {
        width: 90% !important;

        .login-left {
          padding: 30px !important;
          width: 40% !important;
          justify-content: flex-start !important;
          margin-top: 36px !important;
        }

        .login-right {
          width: 63% !important;

          .login-form-container {

            h2 {
              margin-bottom: 50px !important;
            }

            .verify-container {
              flex-direction: column !important;
              align-items: center !important;

              .verify-buttons {
                gap: 7px !important;

              }
            }
          }
        }
      }
    }

    .register-hero-bg {
      height: 68% !important;
    }

    .register-hero-filter {

      height: 68% !important;
    }
  }
}

@media only screen and (min-width : 1024px) and (max-width: 1200px) {
  .register-page {

    .register-hero {
      height: 60vh !important;

      .login-container {
        width: 90% !important;

        .login-left {
          padding: 30px !important;
          width: 40% !important;
          justify-content: flex-start !important;
          margin-top: 36px !important;
        }

        .login-right {
          width: 63% !important;

          .login-form-container {

            h2 {
              margin-bottom: 50px !important;
            }

            .verify-container {
              flex-direction: column !important;
              align-items: center !important;

              .verify-buttons {
                gap: 7px !important;

              }
            }
          }
        }
      }
    }

    .register-hero-bg {
      height: 68% !important;
    }

    .register-hero-filter {
      width: 100%;
      height: 68%;
    }
  }
}

.register-page {
  background-color: var(--primary-bg-color);

  .register-hero {
    position: relative;
    height: 107vh; // Adjust based on your header and footer heights
    overflow: hidden;
    background-color: var(--primary-bg-color);

    .register-hero-bg {
      width: 100%;
      height: 89%;
      animation: moveAndZoom 40s infinite ease-in-out;
    }

    @keyframes moveAndZoom {
      0% {
        transform: translate(5%, 5%) scale(1.25);
      }

      25% {
        transform: translate(2%, 2%) scale(1.3);
      }

      50% {
        transform: translate(0%, 0%) scale(1.35);
      }

      75% {
        transform: translate(2%, 2%) scale(1.3);
      }

      100% {
        transform: translate(5%, 5%) scale(1.25);
        /* Matches the starting state */
      }


    }

    .register-hero-filter {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 59px;
      background: #1717176b;
    }

    .hero-clouds {
      position: absolute;
      top: -180px;
      right: -908px;
      animation: moveDiagonal 20s infinite ease-in-out;
    }

    @keyframes moveDiagonal {
      from {
        transform: translate(0, 10%);
      }

      to {
        transform: translate(10%, -10%);
      }
    }


    .login-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 90%;
      height: 100%;
      // background-color: rgba(255, 255, 255, 0.9);
      border-radius: 10px;
      overflow: hidden;
      // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .login-left {
        width: 65%;
        padding: 40px 168px 40px 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;

        .login-left-content {
          color: white;

          .logo-fk {
            width: 40px;
            margin-bottom: -15px;
          }

          h2 {
            font-family: 'Montserrat';
            font-size: 28px;
            font-weight: 400;
            font-style: italic;
            line-height: 39px;
            margin-bottom: 1px;
          }

          h1 {
            font-family: 'Montserrat';
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 14px;
            line-height: 54px;
            color: #FFF;
            margin-top: 5px;
          }

          p {
            font-family: 'Montserrat';
            font-size: 20px;
            font-style: italic;
            font-weight: 400;
            line-height: 39px;

          }
        }
      }

      .login-right {
        width: 100%;
        padding: 20px 25px 20px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        // margin-top: 147px;
        border-radius: 14px;
        height: 510px;
        box-sizing: border-box;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        // &.blur {
        //   filter: blur(1px);
        //   pointer-events: none;
        //   background-color: #D1D0D0;
        //   // Disable interactions with the blurred content
        // }


        .login-form-container {
          width: 90%;
          margin-top: -15px;

          h2 {
            font-size: 40px;
            font-weight: 400;
            margin-bottom: 25px;
            font-family: 'Montserrat';
          }

          .form-group {
            margin-bottom: 16px;

            label {
              display: block;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 6px;
              color: #333;
            }

            input {
              width: 100%;
              padding: 8px 12px;
              // border: 1px solid #ccc;
              border-radius: 4px;
            }

            .password-input {
              position: relative;

              .password-toggle {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
                color: #999;
                cursor: pointer;
              }
            }

            .select-wrapper .ant-dropdown-trigger {
              border: 1px solid #C3C4CD;
              padding: 6px 11px;
              font-size: 12px;
              border-radius: 5px;
              color: #C3C4CD;
            }

            .select-wrapper .ant-dropdown-trigger .ant-space-gap-col-small {
              column-gap: 310px;
            }
          }

          .info-text {
            display: flex;
            background-color: #F6F6F7;
            width: 100%;
            height: 31%;

            p {
              font-size: 10px;
            }
          }

          .info-text img {
            height: 18px;
            width: 18px;
            padding: 7px 0px 0px 8px;
            margin-right: 6px;
          }

          .verify-container {
            display: flex;
            justify-content: flex-start;
            background-color: #F6F6F7;
            padding: 6px;

            .verify-buttons {
              display: flex;
              margin: 0px 15px;
              gap: 5px;
            }
          }

          .form-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            .remember-me {
              display: flex;
              align-items: center;

              input {
                margin-right: 8px;
              }
            }

            .forgot-password {
              color: #4a0e4e;
              text-decoration: none;
              font-size: 14px;
            }

            .verify-buttons {
              display: flex;
              justify-content: space-around;
              width: 58%;
            }
          }

          .submit-button {
            width: 100%;
            background-color: var(--primary-color);
            color: white;
            padding: 12px;
            border: none;
            border-radius: 12px;
            font-size: 16px;
            cursor: pointer;
            margin-top: 20px;

            &:hover {
              // background-color: #3a0b3e;
            }
          }

          .social-login {
            display: flex;
            justify-content: start;
            gap: 26px;
            width: 100%;
            margin-left: 22px;
            margin-top: 20px;

            .social-button {
              height: 49px;
              border-radius: 32px;
              border: 1px solid #333;
              background: #FFF;
              padding: 15px 15px 15px 7px;
              margin-left: -14px;
              cursor: pointer;
              width: 47%;
              /* margin-right: -19px; */
              box-sizing: border-box;
              display: flex;
              gap: 7px;
              justify-content: center;
              align-items: center;

              p {
                font-size: 15px;
              }
            }

            .social-button img {
              width: 20%;
            }
          }

          .signup-prompt {
            text-align: left;
            margin-bottom: 40px;
            font-size: 14px;
            color: #666;
            margin-top: 10px;

            a {
              color: #4a0e4e;
              font-weight: 600;
              text-decoration: none;
            }
          }
        }


      }

      // .verification-popup {
      //   position: absolute; // Make it float above other content
      //   z-index: 100; // Ensure it stays above the blurred background
      //   // Add more styles as needed for the popup
      // }



    }
  }

  .faq-section {
    background-color: var(--primary-bg-color);
    padding: 50px 0px;
  }

  .help-section {
    background-color: var(--primary-bg-color);
  }


}
@media only screen and (max-width : 480px) {
  .invoice-feature-container {
    padding: 50px !important;

    .feature-cards {
      flex-direction: column !important;
      width: 100% !important;

      .feature-card {
        width: 81% !important;
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {
  .invoice-feature-container {
    padding: 50px !important;
  }
}

@media only screen and (min-width : 821px) and (max-width: 921px) {}

@media only screen and (min-width : 921px) and (max-width: 1024px) {}

.invoice-feature-container {
  font-family: Arial, sans-serif;
  width: 100%;
  margin: 0 auto;
  padding: 50px 85px;
  background-color: var(--primary-bg-color);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .gst-invoice-info {
    text-align: center;
    color: #333;
    // margin-bottom: 40px;
    // font-size: 20px;
    // font-weight: 400;
    text-align: left;
    color: var(--text, #1F212B);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .feature-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .feature-card {
      border-radius: 12px;
      background: #FFF;
      box-shadow: 0px 0px 25px 0px rgba(116, 0, 59, 0.05);
      padding: 20px;
      width: calc(33.333% - 14px);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      /* Smooth transition for transform and shadow */

      &:hover {
        transform: scale(1.05);
        /* Enlarge by 5% on hover */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        /* Slightly stronger shadow on hover */
      }



      h3 {
        color: #111;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 133.333% */
      }

      p {
        color: #475467;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }


      .icon {
        width: 40px;
        height: 40px;
        margin-bottom: 15px;
        border: 1px solid #74003B;
        border-radius: 50%;
      }

      .document-icon img {
        width: 70%;
      }

      .bag-icon img {
        width: 100%;
      }

      .download-icon img {
        width: 94%;
      }

      .feature-card h3 {
        color: #800020;
        margin-bottom: 10px;
      }

      .feature-card p {
        color: #666;
        font-size: 14px;
      }
    }
  }
}
.tax-manager-container {
  display: flex;
  align-items: center;
  padding: 70px;


  .tax-manager-image-container {
    margin-right: 61px;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(116, 0, 59, 0.08);
    width: 72%;
    height: 633px;
    box-sizing: border-box;

    .tax-manager-image {
      width: 100%;
      height: 344px;
      object-fit: cover;
      border-radius: 24px 24px 0px 0px
    }

    .image-heading {
      color: #292929;
      /* font-family: "Noto Sans"; */
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      box-sizing: border-box;
      padding: 25px;
    }

    .image-text {
      color: #292929;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 31px;
      box-sizing: border-box;
      padding: 20px;
    }
  }

  form {
    display: flex;
    align-items: center;
    width: 100%;
  }



  .tax-manager-info-container {
    width: 100%;
    border-radius: 24px;
    background: var(--stroke-main-screen, #FFF);
    box-shadow: 0px 0px 15px 0px rgba(116, 0, 59, 0.08);
    box-sizing: border-box;
    padding: 30px;
    height: 633px;

    .skip-btn-container {
      display: flex;
      justify-content: flex-end;

      .tax-manager-skip-button {
        border-radius: 16px;
        border: 2px solid var(--seconadry-1000, #7C7C7C);
        display: flex;
        height: 27px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: var(--seconadry-1000, #7C7C7C);
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: #ffffff;
      }
    }



    .form-header {
      color: #000;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      box-sizing: border-box;
      padding: 20px;
    }

    .form-group {
      box-sizing: border-box;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;

    }

    .info-text {
      display: flex;
      background-color: #F6F6F7;
      width: 94%;
      height: 7%;
      margin-left: 19px;
      border-radius: 4px;
      margin-top: 8px;

      p {
        font-size: 12px;
      }
    }

    .info-text img {
      height: 20px;
      width: 20px;
      padding: 11px 0px 0px 8px;
      margin-right: 6px;
    }

    .tax-manager-name,
    .tax-manager-email,
    .tax-manager-mobile,
    .tax-manager-linkedin-link {
      margin: 4px 0;
      font-size: 16px;

      input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .tax-manager-name {
      margin-top: 0;
      font-size: 18px;
      font-weight: bold;
    }

    .tax-manager-email,
    .tax-manager-mobile {
      color: #666;
    }

    .tax-manager-linkedin-link {
      a {
        color: #0077b6;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .tax-manager-submit-button {
      color: white;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      margin: 39px 17px;
      border-radius: 7px;
      background: var(--priamary-800, #74003B);
      width: 94%;
      height: 7%;

      &:hover {
        transform: scale(1.05);
        transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
        background: var(--priamary-800, #74003B);
      }
    }
  }
}
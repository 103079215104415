.success-container {
  max-width: 600px;
  padding: 2rem;
  text-align: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0px auto;

  .success-icon-container {

    width: 150px;
    height: 150px;
    background: #E5F4ED;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .success-icon {
      width: 86px;
      height: 86px;
      /* margin: 0 auto 1.5rem; */
      background: #23A26D;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 24px;
        height: 24px;
        color: #ffffff;
      }
    }
  }


  .success-title {
    padding-top: 5px;
    font-size: 40px;
    font-weight: 600;
    color: #111827;
    text-align: center;
  }

  .success-message {
    font-size: 24px;
    color: #374151;
  }

  .success-submessage {
    color: rgba(63, 62, 62, 0.80);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -11px;
    margin-bottom: 42px;
  }

  .success-actions {
    display: flex;
    gap: 1rem;

    button {
      flex: 1;
      padding: 0.75rem 1rem;
      border-radius: 6px;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.2s;
    }

    .preview-button {
      background: transparent;
      border: 1px solid #74003B;
      color: #74003B;

      &:hover {
        // background: #f3f4f6;
      }
    }

    .submit-button {
      background: #74003B;
      border: none;
      color: white;

      &:hover {
        background: #74003B;
      }
    }
  }


}
@media only screen and (max-width : 480px) {
  .contact-form-container {
    padding: 0px !important;
    width: 100% !important;

    h1 {
      text-align: center !important;
      font-size: 56px !important;
    }

    p {
      text-align: center !important;
    }

    h2 {
      text-align: center !important;
    }

    .form-row {
      flex-direction: column !important;
      gap: 25px !important;
    }

    .submit-button {
      width: 100% !important;
      height: 45px !important;
    }

  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {
  .contact-form-container {
    padding: 20px 30px !important;

    h1 {
      font-size: 35px !important;
    }

    p {
      font-size: 20px !important;
    }
  }

}

@media only screen and (min-width : 821px) and (max-width: 921px) {
  .contact-form-container {
    padding: 20px 30px !important;

    h1 {
      font-size: 35px !important;
    }

    p {
      font-size: 20px !important;
    }
  }
}

@media only screen and (min-width : 921px) and (max-width: 1024px) {
  .contact-form-container {
    padding: 20px 30px !important;

    h1 {
      font-size: 35px !important;
    }

    p {
      font-size: 20px !important;
    }
  }
}

@media only screen and (min-width : 1024px) and (max-width: 1200px) {}


.contact-form-container {
  width: 100%;
  padding: 40px 100px 40px 110px;
  // font-family: Arial, sans-serif;
  box-sizing: border-box;


  h1 {
    color: var(--primary-heading-color);
    font-size: 40px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  p {
    color: #343434;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
  }

  h2 {
    color: #222222;
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 600;
  }


  .form-row {
    display: flex;
    gap: 82px;
    margin-bottom: 34px;

    .form-group {
      flex: 1;


      label {
        display: block;
        margin-bottom: 16px;
        color: #888888;
        font-weight: 600;
      }

      input,
      textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 15px;
      }

      textarea {
        height: 100px;
        resize: vertical;
      }
    }
  }

  .submit-btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

  }


}










// @media (max-width: 768px) {
//   .form-row {
//     flex-direction: column;
//     gap: 0;
//   }

//   .form-group {
//     margin-bottom: 20px;
//   }
// }
.top-header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 66px;
}

.header-content {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 32px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 40px;

  img {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
}

.header-actions {
  display: flex;
  gap: 20px;
  align-items: center;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: none;
  background: none;
  color: #656565;
  cursor: pointer;
  font-size: 14px;
  transition: color 0.2s ease;

  &:hover {
    // background-color: #e3e3e3;
    transform: scale(1.1);
    color: #74003b;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }
}

.action-btn img {
  width: 24px;
}

.faq-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #666;
  color: white;
  font-size: 12px;
}

.profile-btn {
  color: #333;
  font-weight: 500;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .gst-invoice-container {
    flex-direction: column !important;
    width: 100% !important;
    padding: 0px !important;

    .invoice-image {
      width: 100% !important;
      padding: 0px !important;
    }

    .invoice-image img {
      width: 100% !important;
    }

    .content {
      width: 100% !important;
      padding: 0px !important;

      .hero-section-button {
        width: 58% !important;
        margin: 34px 83px 7px 83px !important;
      }

      h1 {

        text-align: center !important;
      }

      p {
        text-align: center !important;

      }

      .stats {
        flex-direction: column !important;
        justify-content: center !important;
      }

    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {

  .gst-invoice-container {
    padding: 20px 30px 50px 30px !important;

    .invoice-image {
      width: 53% !important;
    }

    .invoice-image img {
      width: 100% !important;
    }

    .content {

      h1 {
        font-size: 30px !important;
      }

      p {
        font-size: 18px !important;
      }

      .stats {
        flex-wrap: wrap !important;
        gap: 17px !important;
        justify-content: flex-start !important;
        width: 374px !important;
      }

      .stat-item {



        .custom-statistic-title {

          font-size: 15px !important;
        }

        .custom-statistic-value .ant-statistic-content-value {
          font-size: 24px !important;
        }

      }
    }
  }
}

@media only screen and (min-width : 821px) and (max-width: 921px) {
  .gst-invoice-container {
    padding: 20px 30px 50px 30px !important;

    .invoice-image {
      width: 53% !important;
    }

    .invoice-image img {
      width: 100% !important;
    }

    .content {

      h1 {
        font-size: 30px !important;
      }

      p {
        font-size: 18px !important;
      }

      .stats {
        flex-wrap: wrap !important;
        gap: 17px !important;
        justify-content: flex-start !important;
        width: 374px !important;
      }

      .stat-item {



        .custom-statistic-title {

          font-size: 15px !important;
        }

        .custom-statistic-value .ant-statistic-content-value {
          font-size: 24px !important;
        }

      }
    }
  }
}

@media only screen and (min-width : 921px) and (max-width: 1024px) {
  .gst-invoice-container {
    padding: 20px 30px 50px 30px !important;

    .invoice-image {
      width: 53% !important;
    }

    .invoice-image img {
      width: 100% !important;
    }

    .content {

      h1 {
        font-size: 30px !important;
      }

      p {
        font-size: 18px !important;
      }

      .stats {
        flex-wrap: wrap !important;
        gap: 17px !important;
        justify-content: flex-start !important;
        width: 374px !important;
      }

      .stat-item {



        .custom-statistic-title {

          font-size: 15px !important;
        }

        .custom-statistic-value .ant-statistic-content-value {
          font-size: 24px !important;
        }

      }
    }
  }
}

@media only screen and (min-width : 1024px) and (max-width: 1200px) {}

.gst-invoice-container {
  display: flex;
  width: 100%;
  // margin: 0 auto;
  box-sizing: border-box;
  // padding: 20px 21px 20px 21px;
  background-color: #ffffff;
  border-radius: 10px;
  flex-direction: column;


  .invoice-image-container {
    // flex: 1;
    // padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }

  .invoice-image {
    width: 100%;
    height: 558px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: moveAndZoomUp 25s infinite ease-in-out;

  }

  @keyframes moveAndZoomUp {
    0% {
      transform: translate(0, 10px) scale(1.1);
    }

    50% {
      transform: translate(65px, -10px) scale(1.15);
    }

    100% {
      transform: translate(0, 10px) scale(1.1);
    }

  }

  .landing-hero-filter {
    width: 100%;
    height: 558px;
    position: absolute;
    top: 64px;
    background: rgb(23 23 23 / 28%);
  }

  .hero-clouds {
    position: absolute;
    top: -1005px;
    right: 300px;
    animation: moveDiagonallyDown 20s infinite ease-in-out;
  }

  @keyframes moveDiagonallyDown {
    0% {
      transform: translate(10%, -10%);
    }

    100% {
      transform: translate(0, 10%);
    }
  }



  .content {
    // flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 99px;
    text-align: center;
    box-sizing: border-box;


    .hero-section-button {
      position: relative;
      overflow: hidden;

      color: #fff;
      padding: 1rem 2rem;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      outline: 0;
      border: 1px solid #460023;
      border-radius: 0.25rem;
      // box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }


    .lorem-ipsum {
      align-self: flex-end;
      color: var(--primary-color);
      font-style: italic;
      margin-bottom: 10px;
    }

    h1 {
      color: var(--primary-heading-color);
      font-size: 34px;
      margin-bottom: 0px;
      font-weight: 600;
      opacity: 0;
      animation: textAppear 4s ease-out forwards;
      /* Optional: add a slight delay to start animation */
      animation-delay: 0.6s;

    }

    p {
      font-size: 20px;
      line-height: 1.6;
      color: #343434;
      margin-bottom: 30px;
      font-weight: 400;
    }

    .stats {
      display: flex;
      justify-content: flex-start;
      gap: 100px;
      justify-content: center;
    }

    .stat-item {
      text-align: center;


      .custom-statistic-title {
        color: var(--primary-color);
        font-size: 20px;

      }

      .custom-statistic-value .ant-statistic-content-value {
        color: var(--primary-color);
        font-size: 45px;
        font-weight: 600;
        margin-top: -14px;
      }

    }

  }

  @keyframes textAppear {
    0% {
      opacity: 0;
      // transform: translateY(20px);
      clip-path: inset(0 100% 0 0);
    }

    100% {
      opacity: 1;
      // transform: translateY(0);
      clip-path: inset(0 0 0 0);
    }
  }
}









// @media (max-width: 768px) {
//   .gst-invoice-container {
//     flex-direction: column;
//   }

//   .invoice-image {
//     padding-right: 0;
//     padding-bottom: 20px;
//   }

//   .stats {
//     flex-direction: column;
//     align-items: center;
//   }

//   .stat-item {
//     margin-bottom: 20px;
//   }
// }
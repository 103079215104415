@media only screen and (max-width : 480px) {
  .page-body {
    width: 95% !important;
    padding: 0px 10px !important;
  }

  .page-footer {
    width: 100% !important;
  }
}


.landing-page {
  .page-header {}

  .page-body {
    margin-top: 60px;
  }

  .page-footer {}
}
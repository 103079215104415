@media only screen and (max-width : 480px) {
  .footer {
    width: 100% !important;
    // padding: 35px 80px !important;
    // margin-top: 30px !important;

    .footer-container {
      width: 100% !important;
      padding: 0 !important;

      .footer-content {
        flex-direction: column !important;
      }
    }

    .footer-bottom {

      .powered-by {
        font-size: 27px !important;
      }

      .company-name {
        width: 30% !important;
        margin-bottom: -14px !important;
      }

      .copyright {
        font-size: 13px !important;
      }
    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {
  .footer {

    .footer-container {
      padding: 0px 40px !important;

    }
  }
}

@media only screen and (min-width : 821px) and (max-width: 921px) {
  .footer {

    .footer-container {
      padding: 0px 40px !important;

    }
  }
}

@media only screen and (min-width : 921px) and (max-width: 1024px) {
  .footer {

    .footer-container {
      padding: 0px 40px !important;

    }
  }
}

@media only screen and (min-width : 1024px) and (max-width: 1200px) {}



.footer {
  background-color: #1a1a1a;
  color: white;
  padding: 1.5rem 0;
  // position: fixed;
  bottom: 0;
  left: 0;
  right: 0;


  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;


    .footer-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .footer-nav ul {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 5.5rem;
        margin-bottom: 1rem;
        font-size: 17px;
        font-weight: 400;
      }

      .footer-nav a {
        color: white;
        text-decoration: none;
      }

      .footer-nav a:hover {
        color: #cccccc;
      }

      .social-icons {
        display: flex;
        gap: 1rem;
      }

      .social-icons a {
        color: white;
      }

      .social-icons a:hover {
        color: #cccccc;
      }
    }
  }

  .footer-divider {
    border: none;
    border-top: 1px solid #333;
    margin: 1.5rem 0;
  }

  .footer-bottom {
    text-align: center;

    .powered-by {
      margin-bottom: 25px;
      font-size: 39px;
      font-weight: 600;
    }

    .company-name {
      color: #4db5ff;
      font-weight: 600;
      margin-bottom: -31px;
    }



    .copyright {
      font-size: 15px;
      color: #ffffff;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }
}
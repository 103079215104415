@media only screen and (max-width : 480px) {
  .header {
    .header-container {
      width: 100% !important;
      margin: 0 !important;
      padding: 10px 55px !important;

      .desktop-menu,
      .desktop-auth {
        display: none !important;
      }

      .mobile-menu {
        display: block !important;
      }

      .header-container {
        justify-content: space-between !important;


      }

      .whatsapp-icon img {
        display: none !important;
      }

    }
  }
}

@media only screen and (min-width: 480px) and (max-width: 820px) {
  .header {
    .header-container {
      margin: 0px 49px !important;

      .desktop-menu,
      .desktop-auth {
        display: none !important;
      }

      .mobile-menu {
        display: block !important;
      }

      .header-container {
        justify-content: space-between !important;


      }

      .whatsapp-icon img {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-width : 821px) and (max-width: 921px) {
  .header {
    .header-container {
      margin: 0px 49px !important;

      .desktop-menu,
      .desktop-auth {
        display: none !important;
      }

      .mobile-menu {
        display: block !important;
      }

      .header-container {
        justify-content: space-between !important;


      }

      .whatsapp-icon img {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-width : 921px) and (max-width: 1024px) {
  .header {
    .header-container {
      margin: 0px 49px !important;

      .desktop-menu,
      .desktop-auth {
        display: none !important;
      }

      .mobile-menu {
        display: block !important;
      }

      .header-container {
        justify-content: space-between !important;


      }

      .whatsapp-icon img {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-width : 1024px) and (max-width: 1200px) {}



.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    width: 88%;
    margin: 0 92px;
    box-sizing: border-box;
  }

  .logo img {
    height: 40px;
    width: auto;
  }

  .nav-menu {
    width: 59%;
  }

  .nav-menu ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .nav-menu li {
    margin: 0 26px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;


    &:hover {
      transform: scale(1.05);
      color: #74003B;

    }
  }

  .nav-menu a {
    text-decoration: none;
    color: #333;
    font-weight: 500;

  }

  .nav-menu a.active {
    color: #74003B;
  }


  .auth-buttons {
    display: flex;
    gap: 25px;

    .register-btn,
    .login-btn {
      padding: 8px 16px;
      border-radius: 4px;
      font-weight: 500;
      cursor: pointer;
    }

    .register-btn {
      background-color: #74003B;
      color: white;
      border: none;
    }

    .login-btn {
      background-color: white;
      color: #74003B;
      border: 1px solid #74003B;
    }
  }

  .whatsapp-icon {
    display: flex;
    align-items: center;
  }

  .whatsapp-icon img {
    height: 24px;
    width: auto;
  }


  .mobile-menu {
    display: none;
  }
}

.hover-scale-button {
  transition: all 0.3s ease !important;
}

.hover-scale-button:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}
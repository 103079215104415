.company-details-form {
  // General form styling
  margin-top: 60px !important;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 75px;
  border-radius: 8px;
  background: var(--W-Background, #FFF);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);

  .form-header {
    color: #000;
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 20px;

  }

  .ant-form-item {
    margin-bottom: 20px;

    label {
      display: inline-block;
      color: #666;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }

    input {
      width: 100%;
      padding: 6px;
      border: 1px solid #c3c4cd;
      border-radius: 4px;
      font-size: 1rem;

      &:focus {
        border-color: #40a9ff;
        box-shadow: 0 0 5px rgba(64, 169, 255, 0.5);
      }
    }

    .ant-form-item-explain {
      font-size: 0.875rem;
      color: #ff4d4f;
    }
  }

  .info-text {
    display: flex;
    align-items: center;
    margin: 20px 0;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    p {
      font-size: 12px;
      color: #777;
    }
  }

  .tax-manager-submit-button {
    width: 100%;
    height: 40px;
    font-size: 1rem;
    background-color: #74003B;
    color: #fff;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #610032;
    }

    &:disabled {
      background-color: #74003B;
      color: #999;
      cursor: not-allowed;
    }
  }
}
.ag-root-wrapper{

  border: 1px solid #EDEFF5 !important;
background: #FFFFFF !important;
border-radius: 10px;
}
.ag-theme-custom .ag-header .ag-header-cell{
  background: #FAFAFA !important;
  color: #72747D !important;
font-size: 14px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;
}
.ag-theme-custom .ag-row .ag-cell{
  border: 1px solid #EDEFF5 !important;
  background: #FFFFFF !important;
  color: #777 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  display: flex !important;
  width: 227px !important;
  align-items: center !important;
}

:where(.css-dev-only-do-not-override-6s1het).ant-dropdown .ant-dropdown-menu, :where(.css-dev-only-do-not-override-6s1het).ant-dropdown-menu-submenu .ant-dropdown-menu{
  height: 80px !important;
  
}

/* :where(.css-dev-only-do-not-override-6s1het).ant-popconfirm .ant-popconfirm-buttons button {
  background-color: #74003B !important;
  border-color: #74003B !important;
} */

:where(.css-dev-only-do-not-override-14qv9q6).ant-modal .ant-modal-title {
  margin-bottom: 20px ;
}

:where(.css-dev-only-do-not-override-14qv9q6).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content, :where(.css-dev-only-do-not-override-14qv9q6).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content{
  height: 30px;
}

:where(.css-dev-only-do-not-override-11g4vfx).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item, :where(.css-dev-only-do-not-override-11g4vfx).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item, :where(.css-dev-only-do-not-override-11g4vfx).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title, :where(.css-dev-only-do-not-override-11g4vfx).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title{
  height: 30px !important;
}

